<template>
  <base-collapse
    v-model="modelValue"
    :value
    :class-content="classContent"
    class="f-col b-b b-grey-80"
    data-test-id="vf-accordion"
  >
    <template #toggle="{ expanded }">
      <slot name="toggle" :expanded>
        <div class="relative flex items-center py-6 between" data-test-id="vf-accordion-toggle">
          <span class="truncate">
            {{ title }}
          </span>
          <transition name="fade">
            <vf-icon v-if="expanded" name="minus" class="absolute right-0" />
            <vf-icon v-else name="plus" class="absolute right-0" />
          </transition>
        </div>
      </slot>
    </template>
    <template #default>
      <div data-test-id="vf-accordion-content">
        <slot />
      </div>
    </template>
  </base-collapse>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

defineProps<{
  /**
   * Text that will be rendered in top section if no toggle-slot passed
   */
  title?: string
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Allows for use within a group where only one accordion can be expanded at a time.
   * In this case, all accordions in the group should be bound to the same model value
   * and each individual accordion should have a unique `value`.
   */
  value?: string
}>()

const modelValue = defineModel<boolean | string>('open')
</script>
