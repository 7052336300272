<template>
  <div ref="panel" class="fixed-0 z-overlay flex" :class="[positions[position], { [size === 'lg' ? 'md:px-4' : 'md:px-5']: !isX }]">
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        class="vf-uki69b"
        :style="hideBackdrop ? '' : 'background: #000000bf;'"
        data-test-id="vf-panel-backdrop"
      />
      <vf-dialog-content
        class="vf-vxo7tf"
        :class="[
          transforms[position],
          isX ? 'full p-5 md:w-80 lg:w-98' : [(size === 'lg' ? '<lg:w-full' : 'md:w-2/3'), 'py-10 px-5 md:px-10'],
          classContent,
        ]"
        :style="!isX ? 'max-height: calc(100% - 2.5rem)' : undefined"
      >
        <div
          v-if="position === 'bottom'"
          class="relative top-0 m-a w-20 b-b b-grey-50 -mt-6"
          :class="{ '<lg:-mt-4': size === 'lg' }"
        />
        <slot />
        <base-dialog-close
          v-if="!hideCloseButton"
          class="absolute right-0 top-0 z-1 mr-5"
          :class="position === 'bottom' && size !== 'lg' ? 'mt-2' : 'mt-5'"
          :aria-label="$t.close"
          data-test-id="vf-panel-close"
        >
          <vf-icon name="close" size="xl" pos="right -0.5rem center" />
        </base-dialog-close>
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { PositionsSubUnion } from '#types/positions'
import type { SizesSubUnion } from '#types/sizes'

export type Props = {
  /**
   * Size of the panel.
   */
  size?: SizesSubUnion<'sm' | 'lg'>
  /**
   * Position of the panel.
   */
  position?: PositionsSubUnion<'bottom' | 'left' | 'right'>
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * If backdrop is shown.
   */
  hideBackdrop?: boolean
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  position: 'right',
  size: 'sm'
})

const emit = defineEmits<{
  resolve: []
}>()

const panel = ref()
const isX = computed(() => ['left', 'right'].includes(props.position))
const transforms = {
  bottom: '[.nested-enter-from_&,.nested-leave-to_&]:translate-y-20',
  left: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-x-20',
  right: '[.nested-enter-from_&,.nested-leave-to_&]:translate-x-20'
}
const positions = {
  bottom: 'items-end justify-center',
  left: 'items-center justify-start',
  right: 'items-center justify-end'
}
const dirs = {
  down: 'bottom',
  left: 'left',
  right: 'right'
}

useSwipe(panel, {
  onSwipeEnd(_, dir) {
    if (dirs[dir.toLocaleLowerCase()] === props.position) emit('resolve')
  }
})
</script>
